// firebase.js (أو firebaseConfig.js)
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import 'firebase/firestore'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyA7opbBQe1Whz0KLXOiSUdiMajjvriaSzE",
    authDomain: "app-684d2.firebaseapp.com",
    projectId: "app-684d2",
    databaseURL: 'https://app-684d2-default-rtdb.europe-west1.firebasedatabase.app',
    storageBucket: "app-684d2.appspot.com",
    messagingSenderId: "882810017872",
    appId: "1:882810017872:web:9306451dd8faabf9975955"
};

// تهيئة Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export const db = getFirestore(app);

// Export Storage to Firebase
export {storage}
export default app

// Export Auth to Firebase
export const auth = getAuth(app);
export const firestore = getFirestore(app);
