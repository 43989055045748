import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { get, getDatabase, ref, remove } from 'firebase/database';
import app from '../../firebaseConfig';

export default function MasssgeUs() {
    const [databaseRef, setDatabaseRef] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    // Fetch data from Firebase Realtime Database
    const GetDataBase = async () => {
        setLoading(true); // Set loading to true when starting to fetch
        try {
            const db = getDatabase(app);
            const newDataRef = ref(db, 'user/message');
            const snapshot = await get(newDataRef);

            if (snapshot.exists()) {
                setDatabaseRef(Object.values(snapshot.val()));
            } else {
                setDatabaseRef([]); // Set to empty array if no data
            }
        } catch (err) {
            setError("Error fetching data");
        } finally {
            setLoading(false); // Set loading to false when done
        }
    };

    useEffect(() => {
        GetDataBase();
    }, []);

    const handleDelete = async (id) => {
        try {
            const db = getDatabase(app);
            const messageRef = ref(db, `user/message/${id}`);
            await remove(messageRef);

            // Update state after deletion
            setDatabaseRef((prevData) => prevData.filter((item) => item.id !== id));
        } catch (error) {
            setError("Error deleting data");
        }
    };

    if (loading) return <CircularProgress />;
    if (error) return <div>{error}</div>;

    return (
        <div>
            <h3>Message Us</h3>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>name</th>
                            <th>Email</th>
                            <th>phone</th>
                            <th>message</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {databaseRef.map((item) => (
                            <tr key={item.id}>
                                <td>{item.id.slice(0, 5)}</td>
                                <td>{item.fullName}</td>
                                <td>{item.contactEmail}</td>
                                <td style={{ whiteSpace: "pre-wrap"}}>{item.contactPhone}</td>
                                <td className="table-cell">{item.contactMessage.slice(0, 30)}</td>
                                <td>
                                    <Button variant="contained" color="secondary" onClick={() => handleDelete(item.id)}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <br /><br /><br />
        </div>
    );
}
